import React, { useState } from "react"

const LikeButton = () => {
  const [liked, setLiked] = useState(false)

  const onClick = () => {
    setLiked(!liked)
  }

  return (
    <button
      className="text-white flex items-center gap-2 text-sm border-2 border-neutral-600 hover:bg-neutral-600 duration-200 transition-colors ease-in-out py-3 px-4 rounded-md"
      onClick={onClick}
    >
      {!liked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </button>
  )
}

export default LikeButton
